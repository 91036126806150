import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { LiveVehicle } from "../../types";

interface VehicleCardProps {
  vehicle: LiveVehicle;
}

const VehicleCard: React.FC<VehicleCardProps> = ({ vehicle }) => {
  return (
    <Card sx={{ minWidth: 275, margin: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Vehicle Number: {vehicle.vehicleNumber}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          IMEI: {vehicle.uniqueId}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          VIN: {vehicle.chassisNumber}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VehicleCard;
