import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import DtcManager from './DtcManger'; // Make sure to adjust the import path accordingly

const AccountSettings = () => {

  return (
    <Box sx={{ padding: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Custom Diesel DTC Codes
          </Typography>
          <DtcManager dtcType="Diesel" localStorageKey="customDieselDtcList" />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">
            Custom CNG DTC Codes
          </Typography>
          <DtcManager dtcType="CNG" localStorageKey="customCngDtcList" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountSettings;
