import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

const GET_VEHICLES_WITH_SAME_DTC_PATTERN = gql`
  query GetVehiclesWithSameDtcPattern($dtcPattern: [String]!) {
    getVehiclesWithSameDtcPattern(dtcPattern: $dtcPattern) {
      uniqueId
      trackingStatus
      address
      vehicleNumber
      vehicleModel
      vehicleType
      chassisNumber
      faultCodes {
        code
        description
        faultType
        vehicleType
      }
      healthStatus
      odometer
      hourmeter
      inducementTimeTorque
      inducementTimeSpeed
      adblueLevel
    }
  }
`;

interface DtcPatternTableProps {
  name: string[];
}

const DtcPatternTable: React.FC<DtcPatternTableProps> = ({ name }) => {
  const {
    data: dtcPatternData,
    loading: dtcPatternLoading,
    error: dtcPatternError,
  } = useQuery(GET_VEHICLES_WITH_SAME_DTC_PATTERN, {
    variables: { dtcPattern: name },
  });

  const [tableData, setTableData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);
  const [filterConfig, setFilterConfig] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (dtcPatternData) {
      setTableData(dtcPatternData.getVehiclesWithSameDtcPattern);
    }
  }, [dtcPatternData]);

  const sortedData = React.useMemo(() => {
    if (!sortConfig) return tableData;

    return [...tableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [tableData, sortConfig]);

  const filteredData = React.useMemo(() => {
    if (!filterConfig) return sortedData;

    return sortedData.filter(
      (vehicle) => vehicle.healthStatus === filterConfig
    );
  }, [sortedData, filterConfig]);

  const formatMinutesToHoursMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const handleRowClick = (uniqueId: string) => {
    navigate(`/vehicle-diagnostics?uniqueId=${uniqueId}`);
  };

  const handleLatLngClick = (uniqueId: string) => {
    window.open(
      `https://pro.smlsaarthi.com/tracking/vehicle-details/${uniqueId}`,
      "_blank"
    );
  };

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const renderTable = (data: any[]) => (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
            align="center"
          >
            <TableSortLabel
              active={sortConfig?.key === "serialNo"}
              direction={sortConfig?.key === "serialNo" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("serialNo")}
            >
              Serial No
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >
            <TableSortLabel
              active={sortConfig?.key === "uniqueId"}
              direction={sortConfig?.key === "uniqueId" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("uniqueId")}
            >
              IMEI No
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >
            <TableSortLabel
              active={sortConfig?.key === "vehicleNumber"}
              direction={sortConfig?.key === "vehicleNumber" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("vehicleNumber")}
            >
              Vehicle No <br /> Chassis No
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >Current Location</TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >
            <TableSortLabel
              active={sortConfig?.key === "inducementTimeTorque"}
              direction={sortConfig?.key === "inducementTimeTorque" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("inducementTimeTorque")}
            >
              Level 1 Inducement
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >
            <TableSortLabel
              active={sortConfig?.key === "inducementTimeSpeed"}
              direction={sortConfig?.key === "inducementTimeSpeed" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("inducementTimeSpeed")}
            >
              Level 3 Inducement
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >
            <TableSortLabel
              active={sortConfig?.key === "odometer"}
              direction={sortConfig?.key === "odometer" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("odometer")}
            >
              Odometer <br /> Hourmeter
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >
            <TableSortLabel
              active={sortConfig?.key === "healthStatus"}
              direction={sortConfig?.key === "healthStatus" ? sortConfig.direction : "asc"}
              onClick={() => handleSort("healthStatus")}
            >
              Health Status
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
              borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
            }}
          >Fault Codes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((vehicle: any, index: number) => {
          const faultCodes = Array.isArray(vehicle.faultCodes) ? vehicle.faultCodes : [];

          return (
            <TableRow key={index}>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
                align="center"
              >
                {index + 1}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => handleRowClick(vehicle.uniqueId)}
              >
                {vehicle.uniqueId || "NA"}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
              >
                {vehicle.vehicleNumber || "NA"}
                <br />
                {vehicle.chassisNumber || "NA"}
              </TableCell>
              <TableCell
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
                onClick={() => handleLatLngClick(vehicle.uniqueId)}
              >
                {vehicle.address || "NA"}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
              >
                {vehicle.inducementTimeTorque
                  ? formatMinutesToHoursMinutes(vehicle.inducementTimeTorque)
                  : "NA"}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
              >
                {vehicle.inducementTimeSpeed
                  ? formatMinutesToHoursMinutes(vehicle.inducementTimeSpeed)
                  : "NA"}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
              >
                {vehicle.odometer ? vehicle.odometer + " kms" : "NA"}
                <br />
                {vehicle.hourmeter ? vehicle.hourmeter + " hrs" : "NA"}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}>{vehicle.healthStatus || "NA"}</TableCell>
              <TableCell
                sx={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                }}
              >
                {faultCodes.length > 0
                  ? faultCodes.map((fault: any, idx: number) => {
                    // Check if the fault code is in the name array (dtcPattern)
                    const isMatched = name.includes(fault.code);

                    return (
                      <div
                        key={idx}
                        style={{
                          color: isMatched ? "red" : "black", // Highlight in red if matched
                        }}
                      >
                        {fault.code}
                      </div>
                    );
                  })
                  : "NA"}
              </TableCell>

            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  return renderTable(filteredData);
};

export default DtcPatternTable;
