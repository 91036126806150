import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { IUMPRData } from "../../types";

interface IUMPRTableProps {
  historicData: IUMPRData; // A single IUMPRData object
  liveData: IUMPRData | null; // Assuming live data is a single object
}

const IUMPRTable: React.FC<IUMPRTableProps> = ({ historicData, liveData }) => {
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" component="div" sx={{ padding: 2 }}>
        IUMPR Data Table
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Metric</TableCell>
            <TableCell align="right">Lifetime Value</TableCell> {/* Changed header */}
            <TableCell align="right">Selected Time Value</TableCell> {/* Changed header */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>OBD Monitoring Conditions Encountered</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.obd_monitoring_conditions_encountered}
            </TableCell>
            <TableCell align="right">
              {liveData?.obd_monitoring_conditions_encountered ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Ignition Cycle Counter</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.ignition_cycle_counter}
            </TableCell>
            <TableCell align="right">
              {liveData?.ignition_cycle_counter ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>NMHC Catalyst Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.nmhc_catalyst_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.nmhc_catalyst_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>NMHC Catalyst Monitor Conditions Encountered</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.nmhc_catalyst_monitor_conditions_encountered}
            </TableCell>
            <TableCell align="right">
              {liveData?.nmhc_catalyst_monitor_conditions_encountered ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>NOX Catalyst Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.nox_catalyst_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.nox_catalyst_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>NOX Catalyst Monitor Conditions Encountered Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.nox_catalyst_monitor_conditions_encountered_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.nox_catalyst_monitor_conditions_encountered_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>NOX Adsorber Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.nox_adsorber_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.nox_adsorber_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>NOX Adsorber Monitor Conditions Encountered</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.nox_adsorber_monitor_conditions_encountered}
            </TableCell>
            <TableCell align="right">
              {liveData?.nox_adsorber_monitor_conditions_encountered ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>PM Filter Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.pm_filter_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.pm_filter_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>PM Filter Monitor Conditions Encountered Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.pm_filter_monitor_conditions_encountered_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.pm_filter_monitor_conditions_encountered_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Exhaust Gas Sensor Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.exhaust_gas_sensor_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.exhaust_gas_sensor_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Exhaust Gas Sensor Conditions Encountered Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.exhaust_gas_sensor_conditions_encountered_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.exhaust_gas_sensor_conditions_encountered_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>EGR/VVT Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.egr_vvt_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.egr_vvt_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>EGR/VVT Conditions Encountered Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.egr_vvt_conditions_encountered_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.egr_vvt_conditions_encountered_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Boost Pressure Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.boost_pressure_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.boost_pressure_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Boost Pressure Monitor Conditions Encountered Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.boost_pressure_monitor_conditions_encountered_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.boost_pressure_monitor_conditions_encountered_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Fuel Monitor Completion Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.fuel_monitor_completion_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.fuel_monitor_completion_counts ?? "N/A"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Fuel Monitor Conditions Encountered Counts</TableCell> {/* Bold font weight */}
            <TableCell align="right">
              {historicData.fuel_monitor_conditions_encountered_counts}
            </TableCell>
            <TableCell align="right">
              {liveData?.fuel_monitor_conditions_encountered_counts ?? "N/A"}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IUMPRTable;
