import { Box, Grid, Tabs, Tab, Typography } from "@mui/material";
import React, { useState } from "react";
import Tree from "react-d3-tree";
import { styled } from "@mui/material/styles";
import theme from "../../theme";
import { TabPanel, TabContext } from "@mui/lab";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";

const Hierarchy = () => {
  const CustomTab = styled(Tab)(({ theme }) => ({
    borderBottom: "3.5px solid #e8e8e8",
    "&.MuiButtonBase-root": {
      textTransform: "none",
      fontSize: "1.2rem",
      fontWeight: "600",
      borderBottom: "3.5px solid #e8e8e8",
    },
    "&.MuiTab-wrapper": {
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.black.main,
    },
    "&.Mui-selected": {
      color: theme.palette.black.main,
    },
    "&:focus": {
      opacity: 1,
      color: theme.palette.black.main,
    },
  }));

  const CustomTabPanel = styled(TabPanel)(({ theme }) => ({
    "& .MuiBox-root": {
      padding: "0",
    },
  }));

  const [selectedTab, setSelectedTab] = useState("OVERVIEW");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const orgChart = {
    name: "CEO",
    children: [
      {
        name: "Manager",
        attributes: {
          department: "Production",
        },
        children: [
          {
            name: "Foreman",
            attributes: {
              department: "Fabrication",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
          {
            name: "Foreman",
            attributes: {
              department: "Assembly",
            },
            children: [
              {
                name: "Worker",
              },
            ],
          },
        ],
      },
    ],
  };

  return (
    <>
      <Box
        justifyContent="flex-start"
        sx={{ backgroundColor: "#F6F5F4", padding: 2, height: "100vh" }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          sx={{ padding: 2 }}
        >
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}
            >
              Hierarchy Data
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {/* <TimePeriodSelector
              option={option}
              setFromTs={setfromTime}
              fromTs={fromTime}
              toTs={toTime}
              setToTs={settoTime}
              onOptionChange={handleOptionChange}
              handleSubmit={handleSubmit}
              handleSelectVehicle={handleSelectVehicle}
              vehicles={allVehicleLiveStatus}
              isDefaultSelected={isDefaultSelected}
              selectedVehicle={selectedVehicle}
            /> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ height: "calc(100vh - 128px)" }}>
          <Box sx={{ height: "100%", width: "100%", display: 'flex' }}>
            <TabContext value={selectedTab}>
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  aria-label="SML Network tabs"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: theme.palette.black.main,
                      height: "3.5px",
                    },
                  }}
                >
                  <CustomTab
                    label="Overview"
                    value={"OVERVIEW"}
                    style={{ color: theme.palette.black.main }}
                  />
                  <CustomTab
                    label="ZSM"
                    value={"ZSM"}
                    style={{ color: theme.palette.black.main }}
                  />
                  <CustomTab
                    label="CCM"
                    value={"CCM"}
                    style={{ color: theme.palette.black.main }}
                  />
                  <CustomTab
                    label="Dealers"
                    value={"DEALERS"}
                    style={{ color: theme.palette.black.main }}
                  />
                  <CustomTab
                    label="VIN"
                    value={"VIN"}
                    style={{ color: theme.palette.black.main }}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel value={"OVERVIEW"}>
                <Box sx={{ width: "100%", height: "100%" }}>
                  <Tree data={orgChart} />
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={"ZSM"}>ZSM Content</CustomTabPanel>
              <CustomTabPanel value={"CCM"}>CCM Content</CustomTabPanel>
              <CustomTabPanel value={"DEALERS"}>Dealers Content</CustomTabPanel>
              <CustomTabPanel value={"VIN"}>VIN Content</CustomTabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default Hierarchy;
