import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { FuelAdblueData } from '../../types';

interface FuelAdblueTableProps {
  fuelAdblueData: FuelAdblueData;
  liveFuelAdblueData: FuelAdblueData | null; // New prop for live data
}

const FuelAdblueTable: React.FC<FuelAdblueTableProps> = ({ fuelAdblueData, liveFuelAdblueData }) => {
  // Helper function to safely get numbers, defaulting to 0 if null
  const safeNumber = (value: number | null) => (value !== null && value !== undefined ? value : 0);

  // Calculate efficiency for both historical and live data
  const adblueEfficiency =
    (safeNumber(fuelAdblueData.total_adblue_consumed)/ safeNumber(fuelAdblueData.total_fuel_consumed)) * 100;
  const liveAdblueEfficiency = liveFuelAdblueData
    ? (safeNumber(liveFuelAdblueData.total_adblue_consumed) / 1000 / safeNumber(liveFuelAdblueData.total_fuel_consumed)) * 100
    : 0;

  // Calculate average speed for both historical and live data
  const averageSpeed = liveFuelAdblueData
    ? (safeNumber(liveFuelAdblueData.total_obd_distance) / safeNumber(liveFuelAdblueData.total_engine_hour)).toFixed(2)
    : 0;
  const lifeTimeAverageSpeed = fuelAdblueData
    ? (safeNumber(fuelAdblueData.total_obd_distance) / safeNumber(fuelAdblueData.total_engine_hour)).toFixed(2)
    : 0;

  // Calculate mileage for both historical and live data
  const mileage = liveFuelAdblueData
    ? (safeNumber(liveFuelAdblueData.total_obd_distance) / safeNumber(liveFuelAdblueData.total_fuel_consumed)).toFixed(2)
    : 0;
  const lifeTimeMileage = fuelAdblueData
    ? (safeNumber(fuelAdblueData.total_obd_distance) / safeNumber(fuelAdblueData.total_fuel_consumed)).toFixed(2)
    : 0;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 2, marginBottom: 1, height: 460 }}>
      <Typography variant="h6" component="div" sx={{ paddingLeft:2, paddingTop:2 }}>
        Fuel and Adblue Data
      </Typography>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Metric</TableCell>
            <TableCell>Lifetime Value</TableCell>
            <TableCell>Selected Time Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Total OBD Distance</TableCell>
            <TableCell>{liveFuelAdblueData ? safeNumber(liveFuelAdblueData.total_obd_distance).toFixed(2) : 'N/A'} km</TableCell>
            <TableCell>{safeNumber(fuelAdblueData.total_obd_distance).toFixed(2)} km</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Total Engine Hours</TableCell>
            <TableCell>{liveFuelAdblueData ? safeNumber(liveFuelAdblueData.total_engine_hour).toFixed(2) : 'N/A'} hours</TableCell>
            <TableCell>{safeNumber(fuelAdblueData.total_engine_hour).toFixed(2)} hours</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Total Fuel Consumed</TableCell>
            <TableCell>{liveFuelAdblueData ? safeNumber(liveFuelAdblueData.total_fuel_consumed).toFixed(2) : 'N/A'} liters</TableCell>
            <TableCell>{safeNumber(fuelAdblueData.total_fuel_consumed).toFixed(2)} liters</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Mileage</TableCell>
            <TableCell>{mileage} km/l</TableCell>
            <TableCell>{lifeTimeMileage} km/l</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Total AdBlue Consumed</TableCell>
            <TableCell>
              {liveFuelAdblueData
                ? safeNumber(liveFuelAdblueData.total_adblue_consumed ? liveFuelAdblueData.total_adblue_consumed / 1000 : 0).toFixed(2)
                : 'N/A'} liters
            </TableCell>
            <TableCell>
              {fuelAdblueData.total_adblue_consumed
                ? safeNumber(fuelAdblueData.total_adblue_consumed).toFixed(2)
                : 'N/A'} liters
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Average Speed</TableCell>
            <TableCell>{averageSpeed} km/h</TableCell>
            <TableCell>{lifeTimeAverageSpeed} km/h</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Fuel vs. AdBlue Efficiency</TableCell>
            <TableCell>{liveAdblueEfficiency.toFixed(2)}%</TableCell>
            <TableCell>{adblueEfficiency.toFixed(2)}%</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default FuelAdblueTable;
