import { Box, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, gql } from "@apollo/client";
import VehicleCard from "../../components/VehicleDiagnostics/VehicleInfoCard";
import FaultCodeHistoryTable from "../../components/VehicleDiagnostics/VehicleFaultCodeCard";
import { LiveVehicle } from "../../types";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import moment from "moment";

// Query to fetch vehicle live fault code data
const GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY = gql`
  query GetVehicleLiveFaultCodeData($uniqueId: String!) {
    getVehicleLiveFaultCodeData(uniqueId: $uniqueId) {
      dfc_name
      description
      major_component
      sub_component
      type_of_error
      dtco_scantool
      fault_type
      fault_type_hex
      dtcsae
      hex
      dtcsae_meterset_updated
      cause
      remedy
      healing_conditions
      lamp_startegy_mil
      heal_trigger
      mil
      svs
      awl
      inducement
      torque_limitation
      reaction
    }
  }
`;

// Query to fetch all vehicle live statuses
const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const VehicleDiagnostics = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const queryParams = new URLSearchParams(location.search);
  const uniqueId = queryParams.get("uniqueId");

  // Query to fetch vehicle fault data
  const [getVehicleLiveStatusAndFaultHistory, { data: queryData, loading: queryLoading, error: queryError }] = useLazyQuery(GET_VEHICLE_LIVE_STATUS_AND_FAULT_HISTORY, {
    fetchPolicy: "network-only",
  });


  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<LiveVehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<LiveVehicle | null>(null); // Initialize as null
  const [fromTime, setFromTime] = useState<number>(Math.floor(new Date().setHours(0, 0, 0, 0) / 1000));
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, setToTime] = useState<number>(Math.floor(Date.now() / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);

  const [getAllVehicleLiveStatus, { loading: liveVehicleDataLoading, data: liveVehicleData, error: errorLiveVehicleData }] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {

    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });
  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [fromTime, toTime, getAllVehicleLiveStatus]);

  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    if (option !== "CUSTOM" && option !== "") {
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      switch (option) {
        case "HOUR":
          setFromTime(currentTimeInSeconds - 60 * 60); // Last 1 hour
          break;
        case "DAY":
          setFromTime(currentTimeInSeconds - 24 * 60 * 60); // Last 1 day
          break;
        case "WEEK":
          setFromTime(currentTimeInSeconds - 7 * 24 * 60 * 60); // Last 7 days
          break;
        case "MONTH":
          setFromTime(currentTimeInSeconds - 30 * 24 * 60 * 60); // Last 30 days
          break;
        default:
          break;
      }
    }
  }, [option]);

  useEffect(() => {
    if (uniqueId) {
      getVehicleLiveStatusAndFaultHistory({ variables: { uniqueId, fromDate: fromTime } });
      const selected = liveVehicleData?.getAllVehicleLiveStatus.find((vehicle: LiveVehicle) => vehicle.uniqueId === uniqueId);
      setSelectedVehicle(selected || null); // Ensure selected is not undefined
    }
  }, [uniqueId, fromTime, getVehicleLiveStatusAndFaultHistory, liveVehicleData]);

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    setOption(e.target.value);
  };

  const handleSubmit = () => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
    } else if (
      (fromTime && fromTime > moment(moment.now()).unix()) ||
      toTime > moment(moment.now()).unix()
    ) {
      enqueueSnackbar("Future dates are not allowed");
    } else {
      setFromTime(fromTime);
      setToTime(toTime);
      if (selectedVehicle) {
        getVehicleLiveStatusAndFaultHistory({ variables: { uniqueId: selectedVehicle.uniqueId, fromDate: fromTime } });
      } else {
        getVehicleLiveStatusAndFaultHistory({ variables: { uniqueId: undefined, fromDate: fromTime } }); // Handle case where no vehicle is selected
      }
    }
  };

  const handleSelectVehicle = (event: React.ChangeEvent<{}>, newValue: LiveVehicle) => {
    setSelectedVehicle(newValue);
  };

  const vehicleData = queryData?.getVehicleLiveFaultCodeData;

  return (
    <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 2 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
            Vehicle Diagnostics Analytics
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TimePeriodSelector
            option={option}
            setFromTs={setFromTime}
            fromTs={fromTime}
            toTs={toTime}
            setToTs={setToTime}
            onOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
            handleSelectVehicle={handleSelectVehicle}
            vehicles={allVehicleLiveStatus}
            isDefaultSelected={isDefaultSelected}
            selectedVehicle={selectedVehicle}
            isDashBoard={false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignContent={"center"} alignItems={"center"}>
        <Grid item xs={12}>
          {selectedVehicle ? (
            <VehicleCard key={selectedVehicle.uniqueId} vehicle={selectedVehicle} />
          ) : (
            <Box alignContent={"center"}><Typography textAlign={"center"}>No vehicle selected</Typography></Box>

          )}
        </Grid>
        <Grid item xs={12}>
          {vehicleData ? (
            <FaultCodeHistoryTable key={selectedVehicle?.uniqueId} faultCodeHistory={vehicleData} />
          ) : (
            <Typography textAlign={"center"}>No fault code history available</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default VehicleDiagnostics;
