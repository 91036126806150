import React, { ChangeEvent, FC } from "react";
import { Grid, Select, MenuItem, Button } from "@mui/material";
import MuiDatePicker from "./DatePicker";
import CustomSelect from "./AutoComplete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import theme from "../../theme";
import { LiveVehicle } from "../../types";

interface TimePeriodSelectorProps {
  isDashBoard: boolean;
  option: string;
  onOptionChange?: any;
  setFromTs: any;
  fromTs: number;
  toTs: number;
  setToTs: any;
  handleSubmit?: any;
  handleSelectVehicle?: any;
  vehicles?: any;
  isDefaultSelected?: boolean;
  selectedVehicle: any;
}

const TimePeriodSelector: FC<TimePeriodSelectorProps> = ({
  isDashBoard,
  option,
  onOptionChange,
  setToTs,
  fromTs,
  toTs,
  setFromTs,
  handleSubmit,
  handleSelectVehicle,
  vehicles,
  isDefaultSelected,
  selectedVehicle,
}) => {
  const fontStyling = {
    ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      fontWeight: 600,
    },
  };
  const CustomfontStyling = {
    ".css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
    {
      fontWeight: 600,
    },
  };

  const filterVehicles = (options: LiveVehicle[], { inputValue }: { inputValue: string }) => {
    return options.filter((option) => {
      const vehicleNumber = option.vehicleNumber?.toLowerCase() || '';
      const uniqueId = option.uniqueId?.toLowerCase() || '';
      const chassisNumber = option.chassisNumber?.toLowerCase() || '';

      const searchValue = inputValue.toLowerCase();

      return (
        vehicleNumber.includes(searchValue) ||
        uniqueId.includes(searchValue) ||
        chassisNumber.includes(searchValue)
      );
    });
  };


  return (
    <Grid container justifyContent={"flex-end"} gap={1}>
      {!isDashBoard && (
        <Grid item xs={12} sm={4} md={3} lg={3} xl={3} sx={{ ...fontStyling }}>
          <CustomSelect<LiveVehicle>
            options={vehicles}
            placeholder="Select Vehicle"
            handleSelectChange={handleSelectVehicle}
            label={""}
            getOptionLabel={(option) => option.vehicleNumber.toUpperCase()}
            value={selectedVehicle}
            filterOptions={filterVehicles}
          />

        </Grid>
      )}
      <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{ ...CustomfontStyling }}>
        <Select
          value={option}
          onChange={onOptionChange}
          style={{ width: "100%" }}
        >
          <MenuItem value="CUSTOM">Custom</MenuItem>
          {/* <MenuItem value="HOUR">Last Hour</MenuItem>
          <MenuItem value="DAY">Last Day</MenuItem> */}
          <MenuItem value="WEEK">Last Week</MenuItem>
          <MenuItem value="MONTH">Last Month</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={2.5} xl={2.5} sx={{ ...fontStyling }}>
        <MuiDatePicker
          key={fromTs}
          dateChange={setFromTs}
          value={fromTs}
          maxDate={Math.floor(Date.now() / 1000)}
          disabled={!isDefaultSelected}
        />
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={2.5} xl={2.5} sx={{ ...fontStyling }}>
        <MuiDatePicker
          dateChange={setToTs}
          value={toTs}
          maxDate={Math.floor(Date.now() / 1000)}
          disabled={!isDefaultSelected}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={1} lg={1} xl={1}>
        <Button
          style={{
            backgroundColor: theme.palette.black.main,
            height: "99%",
            borderRadius: "0.3rem",
            width: "2rem",
          }}
          onClick={handleSubmit}
        >
          <ArrowForwardIcon style={{ color: theme.palette.white.main }} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default TimePeriodSelector;
