import { Box, Grid, Typography, TextField, MenuItem, CircularProgress } from "@mui/material";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import { useEffect, useState } from "react";
import { LiveVehicle } from "../../types";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import { gql, useLazyQuery } from "@apollo/client";
import DataTable from "../../components/DownloadData/DownloadTable";
import { Button } from "react-bootstrap";

const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const GET_VEHICLE_PARSED_DATA = gql`
query GetVehicleParsedData($uniqueId: String!, $fromTs: Int!, $toTs: Int!, $emailId: String, $limit: Int) {
  getVehicleParsedData(uniqueId: $uniqueId, fromTs: $fromTs, toTs: $toTs, emailId: $emailId, limit: $limit) {
    timestamp
    uniqueId
    header
    frame_number
    fuel_consumption
    trip_fuel_consumption
    fuel_level
    obdDistance
    obd_distance_from_diesel_ecu
    ambient_air_temperature
    runTime
    vehicleSpeed
    brake_failure
    dtcCodes
    protect_lamp
    amber_warning_lamp
    red_stop_lamp
    mil_lamp
    flash_protect_lamp
    flash_amber_lamp
    flash_red_stop_lamp
    flash_mil_lamp
    coolant
    engineFuelTemp
    current_gear
    battery_voltage
    air_cleaner_choke_indicator
    oil_bypass_filter
    low_oil_pressure
    dpf_lamp_amber
    dpf_active_regeneration_status
    mass_load_percentage
    water_in_fuel_lamp
    percent_in_optimal_zone
    engine_torque_mode
    drive_demand_torque
    actual_engine_torque
    engine_speed
    com_adrSrcAdrCtlDev
    com_stStSys
    engine_demand_torque
    com_stEngTrqMode
    adblue_level
    urea_tank_temperature
    variantNo
    latitude
    longitude
    event_flag
    firmwareVersion
    packetEventCode
    vehicleRegNumber
    gpsStatus
    speed
    angle
    satellites
    gpsSignal
    adc_1
    adc_2
    extBatVol
    bat_percent
    packet_type
    packet_id
    operator_name
    plusCode
    can_raw_data
    messageType
    distance_trip_A
    distance_trip_B
    fuel_efficiency_trip_A
    fuel_efficiency_trip_B
    power_eco_mode
    inducement_time_torque
    inducement_time_speed
    low_fuel_warning
    obd_monitoring_conditions_encountered
    ignition_cycle_counter
    nmhc_catalyst_monitor_completion
    nmhc_catalyst_monitor_conditions_encountered
    nox_catalyst_monitor_completion
    nox_catalyst_monitor_conditions_encountered
    nox_adsorber_monitor_completion
    nox_adsorber_monitor_conditions_encountered
    pm_filter_monitor_completion
    pm_filter_monitor_conditions_encountered
    exhaust_gas_sensor_monitor_completion
    exhaust_gas_sensor_monitor_conditions_encountered
    egr_value_vvt_monitor_completion
    egr_value_vvt_monitor_conditions_encountered
    boost_pressure_monitor_completion
    boost_pressure_monitor_conditions_encountered
    fuel_monitor_completion
    fuel_monitor_conditions_encountered
    ems_ecu_software_version
    distance_in_kms_for_last_regeneration
    meter_set_software_version
    number_of_regeneration_demands
    number_of_successfull_regenerations
    urea_quality
    scr_adaptation_factor
    soot_mass
    air_conditioner_ficd_request
    long_term_urea_consumption
    remaining_fuel
    remaining_fuel_volume_in_cng
    fuel_tank_capacity
    control_byte
    low_byte
    high_byte
    number_of_packets
    reserved_byte_assignment_for_sae
    parameter_group_num_of_the_packet_message
    obddistance_from_cng_ecu
    cng_distance_trip_a
    cng_distance_trip_b
    cng_relayed_by_cluster_trip_a
    cng_relayed_by_cluster_trip_b
    actual_engine_torque_high_resolution
    gear
    cng_gas_pressure
  }
}`;

const GET_VEHICLE_RAW_DATA = gql`
query GetVehicleRawData($uniqueId: String!, $fromTs: Int!, $toTs: Int!, $emailId: String, $limit: Int) {
  getVehicleRawData(uniqueId: $uniqueId, fromTs: $fromTs, toTs: $toTs, emailId: $emailId, limit: $limit) {
    server_time
    payload
  }
}`;

const DownloadData = () => {
  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<LiveVehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<LiveVehicle | null>(null);
  const [fromTime, setFromTime] = useState<number>(Math.floor(new Date().setHours(0, 0, 0, 0) / 1000));
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, setToTime] = useState<number>(Math.floor(Date.now() / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [email, setEmail] = useState<string>("");
  const [dataType, setDataType] = useState<string>("Parsed");
  const [limit, setLimit] = useState<string>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // Loading state

  const [getAllVehicleLiveStatus] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });

  const [getVehicleParsedData] = useLazyQuery(GET_VEHICLE_PARSED_DATA, {
    onCompleted: (data: { getVehicleParsedData: any[] }) => {
      setTableData(data.getVehicleParsedData);
      setLoading(false); // Data received, stop loading
    },
    onError: () => {
      setLoading(false); // Error occurred, stop loading
    }
  });

  const [getVehicleRawData] = useLazyQuery(GET_VEHICLE_RAW_DATA, {
    onCompleted: (data: { getVehicleRawData: any[] }) => {
      setTableData(data.getVehicleRawData);
      setLoading(false); // Data received, stop loading
    },
    onError: () => {
      setLoading(false); // Error occurred, stop loading
    }
  });

  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [getAllVehicleLiveStatus]);

  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    if (option !== "CUSTOM" && option !== "") {
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      switch (option) {
        case "HOUR":
          setFromTime(currentTimeInSeconds - 60 * 60);
          break;
        case "DAY":
          setFromTime(currentTimeInSeconds - 24 * 60 * 60);
          break;
        case "WEEK":
          setFromTime(currentTimeInSeconds - 7 * 24 * 60 * 60);
          break;
        case "MONTH":
          setFromTime(currentTimeInSeconds - 30 * 24 * 60 * 60);
          break;
        default:
          break;
      }
    }
  }, [option]);

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const handleSubmit = () => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
    } else if ((fromTime && fromTime > dayjs().unix()) || toTime > dayjs().unix()) {
      enqueueSnackbar("Future dates are not allowed");
    } else if (!selectedVehicle) {
      enqueueSnackbar("Please select a vehicle");
    } else if (email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      enqueueSnackbar("Invalid email address");
    } else {
      setLoading(true); // Start loading when submitting the request
      const parsedLimit = limit ? parseInt(limit) : undefined;
      const variables = {
        uniqueId: selectedVehicle.uniqueId,
        fromTs: fromTime,
        toTs: toTime,
        emailId: email || undefined,
        limit: parsedLimit
      };

      if (dataType === "Parsed") {
        getVehicleParsedData({ variables });
      } else {
        getVehicleRawData({ variables });
      }

      enqueueSnackbar(`${dataType} data requested successfully`, { variant: "success" });
    }
  };

  const handleSelectVehicle = (event: React.ChangeEvent<{}>, newValue: LiveVehicle) => {
    setSelectedVehicle(newValue);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleDataTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setDataType(e.target.value as string);
  };

  const handleLimitChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    setLimit(e.target.value as string);
  }

  return (
    <Box justifyContent="flex-start" sx={{ backgroundColor: "#F6F5F4", padding: 2 }}>
      <Grid container spacing={2} justifyContent="flex-start" sx={{ padding: 1 }}>
        <Grid item xs={4}>
          <Typography sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}>
            Download Data
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TimePeriodSelector
            option={option}
            setFromTs={setFromTime}
            fromTs={fromTime}
            toTs={toTime}
            setToTs={setToTime}
            onOptionChange={handleOptionChange}
            handleSubmit={handleSubmit}
            handleSelectVehicle={handleSelectVehicle}
            vehicles={allVehicleLiveStatus}
            isDefaultSelected={isDefaultSelected}
            selectedVehicle={selectedVehicle}
            isDashBoard={false}
          />
        </Grid>
        <Grid item xs={12}>
        <Grid container spacing={2} mt={"10px"} xs={12}>
          <Grid item xs={3}>
            <TextField
              label="Email (Optional)"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={handleEmailChange}
              error={!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)}
              helperText={!!email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) ? "Invalid email address" : ""}
            />

          </Grid>
          <Grid item xs={3}>
            <TextField
              select
              label="Data Type"
              variant="outlined"
              fullWidth
              margin="normal"
              value={dataType}
              onChange={handleDataTypeChange}
            >
              <MenuItem value="Parsed">Parsed Data</MenuItem>
              <MenuItem value="Raw">Raw Data</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Limit"
              variant="outlined"
              fullWidth
              margin="normal"
              value={limit}
              onChange={handleLimitChange}
            />
          </Grid>
        </Grid>
          {/* Show loader when data is being fetched */}
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            tableData.length > 0 && <DataTable data={tableData} uniqueId={selectedVehicle?.uniqueId!} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DownloadData;
